<template>
	<div class="about">
		<section class="about-top-block flex-col flex justify-between container mx-auto px-8 xl:px-0">

			<div class="about-content flex flex-col xl:grid xl:grid-rows-1 xl:grid-cols-2 gap-4 xl:px-0">
				<div class="about-content-slider-left xl:row-span-1 xl:col-span-1 h-full relative">
					<!-- desktop swiper -->
					<swiper
						v-if="!isMobile"
						:slides-per-view="1"
						:space-between="0"
						:centered-slides="true"
						:centered-slides-bounds="true"
						:direction="'vertical'"
						@swiper="setControlledSwiper"
					>
						<swiper-slide
							v-for="image in topLeftSliderImages.images"
							:key="image.id"
						>
							<img
								:src="image.url"
								alt=""
							>
						</swiper-slide>
					</swiper>
					<!-- mobile swiper -->
					<swiper
						class="mt-4"
						v-if="isMobile"
						:slides-per-view="1"
						:space-between="0"
						:centered-slides="true"
						:centered-slides-bounds="true"
						:direction="'horizontal'"
						:autoplay="{
  'delay': 5000,
  'disableOnInteraction': false
}"
						@slideChange="onMobileTopSlideChange"
						@swiper="setMobileTopSwiper"
					>
						<swiper-slide
							v-for="image in mobileTopSliderImages.images"
							:key="image.id"
						>
							<img
								:src="image.url"
								alt=""
							>
						</swiper-slide>
					</swiper>
					<bullets
						v-if="isMobile"
						:count="mobileTopSliderImages?.images?.length"
						:activeIndex="activeMobileTopIndex"
						color="secondary"
						direction="row"
						@bulletIndex="mobileTopSlideTo"
						class="flex absolute right-0 -bottom-10 xl:left-auto xl:right-0  xl:bottom-auto xl:-top-6"
					></bullets>

				</div>
				<div
					v-if="isMobile"
					class="content-text xl:col-span-4 row-span-1 flex flex-col h-full pt-16 pb-8 xl:py-0 px-0 xl:px-4 2xl:px-6 3xl:px-12 4xl:px-24 text-left justify-between w-full"
				>
					<div
						v-if="isMobile"
						class="flex flex-col"
					>
						<h3 class="content-text-subtitle">{{ topContent.subTitle }}</h3>
						<h2 class="content-text-title">{{ topContent.title }}</h2>
						<span class="content-decoration"></span>

						<p
							v-if="isMobile"
							class="content-text-description"
							v-html="separateParagraph(topContent.description,'/p>')[0]"
						></p>
					</div>
				</div>

				<div
					v-if="!isMobile"
					class="about-content-slider-right row-span-1 xl:col-span-1 h-full relative order-3 xl:order-none"
				>
					<!-- main swiper bullets -->
					<bullets
						v-if="!isMobile"
						:count="topRightSliderImages?.images?.length"
						:activeIndex="activeMainIndex"
						color="secondary"
						direction="row"
						@bulletIndex="mainSlideTo"
						class="flex absolute left-0 xl:left-auto xl:right-0 -bottom-6 xl:bottom-auto xl:-top-6"
					></bullets>

					<!-- main swiper -->
					<!-- desktop swiper -->
					<swiper
						v-if="!isMobile"
						:slides-per-view="1"
						:space-between="0"
						:centered-slides="true"
						:centered-slides-bounds="true"
						:autoplay="{
  'delay': 5000,
  'disableOnInteraction': false
}"
						:direction="'horizontal'"
						:controller="{ control: controlledSwiper }"
						@swiper="setMainSwiper"
						@slideChange="onSlideChange"
					>
						<swiper-slide
							v-for="image in topRightSliderImages.images"
							:key="image.id"
						>
							<img
								:src="image.url"
								alt=""
							>
						</swiper-slide>
					</swiper>

				</div>
				<div
					v-if="isMobile"
					class="about-content-slider-right row-span-1 xl:col-span-4 h-full relative "
				>

					<!-- mobile swiper -->
					<swiper
						v-if="isMobile"
						:slides-per-view="1"
						:space-between="0"
						:centered-slides="true"
						:centered-slides-bounds="true"
						:autoplay="{
  'delay': 5000,
  'disableOnInteraction': false
}"
						:direction="'horizontal'"
						:controller="{ control: mobileControlledSwiper }"
						@swiper="setMobileMainSwiper"
						@slideChange="onMobileMainSlideChange"
					>
						<swiper-slide
							v-for="image in mobileMiddleSliderImages.images"
							:key="image.id"
						>
							<img
								:src="image.url"
								alt=""
							>
						</swiper-slide>
					</swiper>
					<swiper
						v-if="isMobile"
						:slides-per-view="1"
						:space-between="0"
						:centered-slides="true"
						:centered-slides-bounds="true"
						:direction="'horizontal'"
						@swiper="setMobileControlledSwiper"
					>
						<swiper-slide
							v-for="image in mobileBottomSliderImages.images"
							:key="image.id"
						>
							<img
								:src="image.url"
								alt=""
							>
						</swiper-slide>
					</swiper>

					<bullets
						v-if="isMobile"
						:count="mobileMiddleSliderImages?.images?.length"
						:activeIndex="activeMobileMainIndex"
						color="secondary"
						direction="row"
						@bulletIndex="mobileMainSlideTo"
						class="flex absolute right-0 xl:left-auto xl:right-0 -bottom-6 xl:bottom-auto xl:-top-6"
					></bullets>
				</div>
				<div class="content-text xl:col-span-4 row-span-1 flex flex-col h-full py-8 xl:py-0 px-0 xl:px-6 2xl:px-6 3xl:px-12 4xl:px-24 text-left justify-between w-full">
					<template
						v-for="text in separateParagraph(topContent.description, '/p>').slice(1)"
						:key="text.id"
					>
						<p
							v-if="isMobile"
							class="content-text-description"
							v-html="text"
						></p>
					</template>

				</div>
			</div>

			<div class="hidden lg:flex justify-between about-header ">
				<div class="header-text text-2xl font-title mr-8">
					<h1 class="header-text-top text-about text-right whitespace-nowrap leading-tight">About Lu Mansion</h1>
					<div class="flex justify-between">
						<p class="text-about leading-none mt-4">-</p>
						<h1 class="header-text-bottom font-medium text-secondary text-xl whitespace-nowrap text-right leading-loose tracking-widest">關於盧經堂</h1>
					</div>
				</div>
				<div
					class="px-8 header-text-block"
					v-for="text in separateParagraph(topContent.description, '/p>').slice(0, 1)"
					:key="text.id"
				>
					<p
						v-if="!isMobile"
						class="content-text-description text-left"
						v-html="text"
					></p>
				</div>
				<div
					class="px-8 header-text-block"
					v-for="text2 in separateParagraph(topContent.description, '/p>').slice(1)"
					:key="text2.id"
				>
					<p
						v-if="!isMobile"
						class="content-text-description text-left"
						v-html="text2"
					></p>
				</div>
				<!-- <div
					v-if="!isMobile"
					class="flex flex-col"
				>
					<h3 class="content-text-subtitle">{{ topContent.subTitle }}</h3>
					<h2 class="content-text-title">{{ topContent.title }}</h2>
					<span class="content-decoration"></span>
				</div> -->

			</div>
		</section>
		<section class="about-bottom-block pt-8 xl:pt-36 container mx-auto px-8 lg:px-0">
			<div class="about-content relative flex flex-col lg:grid lg:grid-cols-11 2xl:grid-cols-12 gap-6">

				<div
					v-if="!isMobile"
					class="about-content-slider-bottom h-full col-span-8"
				>
					<!-- bottom swiper -->
					<!-- desktop swiper -->
					<swiper
						v-if="!isMobile"
						:slides-per-view="1"
						:space-between="20"
						:centered-slides="true"
						:centered-slides-bounds="true"
						:autoplay="{
  'delay': 5000,
'disableOnInteraction': false
}"
						@swiper="setBottomSwiper"
						@slideChange="onBottomSlideChange"
					>
						<swiper-slide
							v-for="image in bottomSliderImages.images"
							:key="image.id"
						>
							<img
								:src="image.url"
								alt=""
							>
						</swiper-slide>
					</swiper>
					<!-- bottom slider bullets -->
					<bullets
						v-if="!isMobile"
						:count="bottomSliderImages?.images?.length"
						:activeIndex="activeBottomIndex"
						color="secondary"
						direction="row"
						@bulletIndex="bottomSlideTo"
						class="flex absolute right-0 lg:left-0 -top-3 lg:-top-6"
					></bullets>
				</div>
				<div class="content-text col-span-3 text-left relative py-4 lg:py-0 flex flex-col justify-between w-full h-full">
					<div
						v-if="isMobile"
						class="about-content-slider-bottom relative"
					>
						<swiper
							v-if="isMobile"
							:slides-per-view="1"
							:space-between="20"
							:centered-slides="true"
							:centered-slides-bounds="true"
							:allowTouchMove="false"
							class="mb-8"
						>
							<swiper-slide
								v-for="image in mobileLuSliderImages.images"
								:key="image.id"
							>
								<img
									:src="image.url"
									alt=""
									class="p-4"
								>
							</swiper-slide>
						</swiper>
						<div
							v-if="isMobile"
							class="mobile-figure"
						>
							<img
								class="md:hidden"
								src="../assets/img/mobile-assets-01.png"
								alt=""
							>
						</div>
					</div>
					<div class="hidden lg:block mt-0 mb-6">
						<div class="about-header-text text-left font-title text-2xl leading-tight">
							<h1 class="header-text-top text-about">The Richest Man</h1>
							<h1 class="header-text-bottom text-about">in Taiwan</h1>
						</div>
					</div>
					<div class="about-content about-content-bottom flex flex-col tracking-widest">
						<h3 class="content-text-subtitle text-about lg:text-secondary">{{ bottomContent.subTitle }}</h3>
						<h2 class="content-text-title">{{ bottomContent.title }}</h2>
						<span class="content-decoration"></span>
					</div>
					<p
						class="content-text-description text-left text-base"
						v-html="bottomContent.description"
					></p>
				</div>
				<div class="about-bottom-right hidden 2xl:block 2xl:col-span-1">
					<img
						src="../assets/img/about-slider-bottom-right.jpg"
						alt=""
					>
				</div>

			</div>
		</section>
		<section class="about-pano-block flex-col flex justify-between container mx-auto px-8 xl:px-0 pt-8 xl:pt-36">

			<div class="flex justify-between items-end about-content mb-6">
				<div class="hidden lg:block about-header-text text-left font-title text-2xl leading-tight">
					<h1 class="header-text-top text-about">Panorama</h1>
					<h1 class="header-text-bottom text-about">Photos</h1>
					<p class="text-about leading-none -mt-2">-</p>
				</div>
				<div class="text-left lg:text-right text-secondary text-xl whitespace-nowrap leading-loose tracking-widest font-medium">
					<h1 class="content-text-subtitle">360度</h1>
					<h1 class="content-text-title">環景照片</h1>

				</div>
			</div>
			<div class="pano-block">
				<iframe
					width='100%'
					height='100%'
					:src='panoramaImages.title'
					frameborder='0'
					webkitallowfullscreen
					mozallowfullscreen
					allowfullscreen
				></iframe>
			</div>
		</section>
		<section class="about-map-block flex-col flex justify-between container mx-auto px-8 xl:px-0 pt-8 xl:pt-36">

			<div class="flex justify-between items-end about-content mb-6">
				<div class="about-header-text hidden lg:block text-left font-title text-2xl leading-tight">
					<h1 class="header-text-top text-about">Google</h1>
					<h1 class="header-text-bottom text-about">Map</h1>
					<p class="text-about leading-none -mt-2">-</p>
				</div>
				<div class="text-left lg:text-right text-secondary text-xl whitespace-nowrap leading-loose tracking-widest font-medium">
					<h1 class="content-text-subtitle">盧經堂古厝</h1>
					<h1 class="content-text-title">位置地圖</h1>
				</div>
			</div>
			<div class="map-block">
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.6404434561914!2d120.15788611496725!3d23.000245684964362!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346e761d48ee0ead%3A0x50fed8cfe8522d5!2z55un57aT5aCC5Y-k5Y6dLemmluWvjOS5i-Wuti_lj7DljZfmipPlkagv5Y-k56au5oqT5ZGo!5e0!3m2!1szh-TW!2stw!4v1633516382218!5m2!1szh-TW!2stw"
					width="100%"
					height="100%"
					style="border:0;"
					allowfullscreen=""
					loading="lazy"
				></iframe>
			</div>
		</section>
	</div>
</template>

<script>
import { ref, reactive, onMounted, computed, h } from "vue";
import { useStore } from "vuex";
import { fetchData } from "../utils/fetchApi";
import { _detectMobile, _removeResizeListener } from "../utils/helpers";
import SwiperCore, {
	Navigation,
	Pagination,
	Autoplay,
	Controller,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import Bullets from "../components/Bullets.vue";
import { separateParagraph } from "../utils/helpers";
// import * as PanoLens from "../utils/panolens.min.js";

SwiperCore.use([Navigation, Pagination, Autoplay, Controller]);

export default {
	components: {
		Swiper,
		SwiperSlide,
		Bullets,
		// Pano,
	},
	setup() {
		const store = useStore();
		const enabledMenuId = store.state.enabledMenuId;
		const isMobile = computed(() => store.state.isMobile);

		const topLeftSliderId = ref(null);
		const topLeftSliderImages = reactive({});
		const topRightSliderId = ref(null);
		const topRightSliderImages = reactive({});
		const bottomSliderId = ref(null);
		const bottomSliderImages = reactive({});
		const mobileTopSliderId = ref(null);
		const mobileTopSliderImages = reactive({});
		const mobileMiddleSliderId = ref(null);
		const mobileMiddleSliderImages = reactive({});
		const mobileBottomSliderId = ref(null);
		const mobileBottomSliderImages = reactive({});
		const mobileLuSliderId = ref(null);
		const mobileLuSliderImages = reactive({});
		const panoramaId = ref(null);
		const panoramaImages = reactive({
			title: "",
			images: [],
		});
		const topContentId = ref(null);
		const topContent = reactive({
			title: "",
			subTitle: "",
			description: "",
		});
		const bottomContentId = ref(null);
		const bottomContent = reactive({});

		// const viewer = ref(null);
		// const panorama = ref(null);

		const idTitleObjArray = [
			{ id: topLeftSliderId, title: "Top Left Slider" },
			{ id: topRightSliderId, title: "Top Right Slider" },
			{ id: topContentId, title: "Top Contents" },
			{ id: bottomSliderId, title: "Bottom Slider" },
			{ id: bottomContentId, title: "Bottom Contents" },
			{ id: panoramaId, title: "Panorama" },
			{ id: mobileTopSliderId, title: "Mobile Top Slider" },
			{ id: mobileMiddleSliderId, title: "Mobile Middle Slider" },
			{ id: mobileBottomSliderId, title: "Mobile Bottom Slider" },
			{ id: mobileLuSliderId, title: "Mobile Lu Slider" },
		];

		// set swiper instances ref
		const controlledSwiper = ref(null);
		const mobileControlledSwiper = ref(null);
		const mainSwiper = ref(null);
		const bottomSwiper = ref(null);
		const mobileTopSwiper = ref(null);
		const mobileMainSwiper = ref(null);

		// set @swiper instances
		const setControlledSwiper = (swiper) =>
			(controlledSwiper.value = swiper);
		const setMobileControlledSwiper = (swiper) =>
			(mobileControlledSwiper.value = swiper);
		const setMainSwiper = (swiper) => (mainSwiper.value = swiper);
		const setBottomSwiper = (swiper) => (bottomSwiper.value = swiper);
		const setMobileTopSwiper = (swiper) => (mobileTopSwiper.value = swiper);
		const setMobileMainSwiper = (swiper) =>
			(mobileMainSwiper.value = swiper);

		// set Bullets slide to @bulletsIndex
		const mainSlideTo = (index) => mainSwiper.value.slideTo(index);
		const bottomSlideTo = (index) => bottomSwiper.value.slideTo(index);
		const mobileTopSlideTo = (index) =>
			mobileTopSwiper.value.slideTo(index);
		const mobileMainSlideTo = (index) =>
			mobileMainSwiper.value.slideTo(index);

		// set current slide index on <swiper>
		const activeMainIndex = ref(0);
		const activeBottomIndex = ref(0);
		const activeMobileTopIndex = ref(0);
		const activeMobileMainIndex = ref(0);
		const onSlideChange = (swiper) => {
			if (swiper.activeIndex == null) return;
			activeMainIndex.value = swiper.activeIndex;
		};
		const onBottomSlideChange = (swiper) => {
			if (swiper.activeIndex == null) return;
			activeBottomIndex.value = swiper.activeIndex;
		};
		const onMobileTopSlideChange = (swiper) => {
			if (swiper.activeIndex == null) return;
			activeMobileTopIndex.value = swiper.activeIndex;
		};
		const onMobileMainSlideChange = (swiper) => {
			if (swiper.activeIndex == null) return;
			activeMobileMainIndex.value = swiper.activeIndex;
		};
		// const initPanoLens = () => {
		// 	viewer.value = new PanoLens.Viewer();
		// 	panorama.value = new PanoLens.ImagePanorama(
		// 		panoramaImages.images[0].url
		// 	);
		// 	viewer.value.add(panorama.value);
		// };
		// onBeforeMount(() => _detectMobile(isMobile));
		onMounted(async () => {
			await fetchData.menuBlocks(enabledMenuId, idTitleObjArray);
			await fetchData.slider(topLeftSliderId, topLeftSliderImages);
			await fetchData.slider(topRightSliderId, topRightSliderImages);
			await fetchData.slider(bottomSliderId, bottomSliderImages);
			await fetchData.slider(panoramaId, panoramaImages);
			await fetchData.content(topContentId, topContent);
			await fetchData.content(bottomContentId, bottomContent);
			await fetchData.slider(mobileTopSliderId, mobileTopSliderImages);
			await fetchData.slider(
				mobileMiddleSliderId,
				mobileMiddleSliderImages
			);
			await fetchData.slider(
				mobileBottomSliderId,
				mobileBottomSliderImages
			);
			await fetchData.slider(mobileLuSliderId, mobileLuSliderImages);

			// await initPanoLens();
		});
		// onUnmounted(() => _removeResizeListener());

		return {
			isMobile,
			topLeftSliderImages,
			topRightSliderImages,
			bottomSliderImages,
			mobileTopSliderImages,
			mobileMiddleSliderImages,
			mobileBottomSliderImages,
			mobileLuSliderImages,
			panoramaImages,
			topContent,
			bottomContent,
			setControlledSwiper,
			controlledSwiper,
			setMainSwiper,
			mainSlideTo,
			setBottomSwiper,
			bottomSlideTo,
			setMobileControlledSwiper,
			mobileControlledSwiper,
			setMobileTopSwiper,
			mobileTopSwiper,
			mobileTopSlideTo,
			setMobileMainSwiper,
			mobileMainSwiper,
			mobileMainSlideTo,
			onSlideChange,
			activeMainIndex,
			activeBottomIndex,
			onBottomSlideChange,
			activeMobileTopIndex,
			onMobileTopSlideChange,
			activeMobileMainIndex,
			onMobileMainSlideChange,
			separateParagraph,
		};
	},
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";

.about-header {
	@apply mb-10 mt-8;
}

.header-text-block {
	border-left: 1px solid $about;
	&:nth-child(3) {
		border-right: 1px solid $about;
	}
}

.about-content {
	width: 100%;
	height: 100%;
	&-bottom {
		@media (max-width: 768px) {
			margin-top: 4rem;
		}
	}
}
.swiper-container {
	height: calc(100vh - 24rem);
	@media (max-width: 768px) {
		height: auto !important;
	}
}
.about-bottom-right {
	height: calc(100vh - 15rem);
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.swiper-slide {
	// width: 80% !important;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		@media (max-width: 1023px) {
			object-fit: contain;
		}
	}
}

.swiper-wrapper {
	height: 100% !important;
	align-items: center !important;
}

.about-content-slider-right {
	width: 105%;
	@media (max-width: 1280px) {
		width: 100%;
	}
}
.about-content-slider-bottom {
	height: 100%;
    overflow: hidden;
	.swiper-container {
		height: calc(100vh - 15rem);
	}
}

.content-text {
	background: url("../assets/img/bg_paper.jpg") center no-repeat;
	background-size: cover;
	@media (max-width: 1024px) {
		background: none;
	}
}
.about-top-block,
.about-bottom-block {
	// @media (min-width: 1024px) {
	// 	width: 100%;
	// 	max-width: unset;
	// }
}
.about-bottom-block {
	width: 100%;
	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url("../assets/img/about-overlay.png") bottom left no-repeat;
		mix-blend-mode: color-burn;
		opacity: 0.5;
		z-index: 0;
		pointer-events: none;
		@media (max-width: 768px) {
			background: none;
		}
	}
	.content-text-title {
		@apply lg:text-xlxl;
	}
}

.mobile-figure {
	width: 150%;
	position: absolute;
	bottom: -25%;
	left: -15%;
	z-index: 2;
}

.pano-block,
.map-block {
	width: 100%;
	height: calc(100vh - 18rem);
	position: relative;
}

.content {
	&-decoration {
		width: 0.75rem;
		height: 0.15rem;
		@apply bg-about my-4;
	}
	&-text {
		&-subtitle {
			@apply text-lg lg:text-xl leading-relaxed tracking-widest;
		}
		&-title {
			@apply text-xl lg:font-medium text-secondary leading-relaxed tracking-widest;
		}
		&-description {
			@apply text-md lg:text-sm leading-loose text-default font-normal font-body;
		}
	}
}
</style>
